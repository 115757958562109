//React imports
import React from "react";
import { useParams} from "react-router-dom";

//Firebase
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase";

import {makeStyles} from '@mui/styles';
import Grid from "@material-ui/core/Grid";
import {Box, Container, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";

//External imports
import BalanceText from "react-balance-text";
import Helmet from "react-helmet";
import Slider from "react-slick";

import _ from "lodash";

//Internal imports

//Components
import {Square} from "../global/components/Square";
import CenterGrid from "../CenterGrid.js";
import ShippingFooter from "../ShippingFooter/ShippingFooter.js";
import { CustomButton } from "../styledComponents.js";
import {useStyles_LandingPage as useStyles} from "./useStyles_LandingPage.js"

//Hooks, utils, data


import { ProductPrice} from "./functions_LandingPage.js";

import {landingSlider, home_slider_settings as slider_settings} from "../data/homeSliderData";
import productData from "../data/productData.js";

import LazyLoad from "react-lazyload";

// import ProductPrice from "../useProductPrice.js";

console.log(landingSlider)

const rowHeight = 400
const useStyles2 = makeStyles((theme) => ({
    fullWidth: {
        display: "block",
        margin: "auto",
        width: "100%",
    },
    appStore: {
        display: "block",
        margin: "0 auto",
        [theme.breakpoints.down('md')]: {
            width: "80%",
        },
    },
    jigpaws: {
        backgroundColor: "#413e40",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
    },
    linebreak1: {
        backgroundColor: '#85a2ad',
        height: "1.5vh",
        filter: "brightness(90%)"
    },
    linebreak2: {
        // marginTop: theme.spacing(4),
        backgroundColor: '#413e40',
        height: "1.5vh",
        // height: "10px",
    },
    see: {
        minHeight: rowHeight,
        height: "calc(40vh - 67px)",
        backgroundColor: "#99bfc2",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down('md')]: {
            height: "25vh",
            minHeight: 0,
        },
    },
    download: {
        minHeight: rowHeight,
        // height: "calc(40vh - 67px)",
        backgroundColor: "#85a2ad",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down('md')]: {
            height: "25vh",
            minHeight: 0,
        },
    },
    transparentDivider: {
        height: "5vh",
        backgroundColor: "#fff",
    },
    galleryLink: {
        paddingTop: 10
    },
    moveToFront: {
        [theme.breakpoints.down('md')]: {
            order: -1,
        },
    }
}));



const buttonStyles = {
    padding: '10px 30px',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.05em",
    border: "1px solid "
};

const bodyStyle = {
    fontSize: '20px',
    '@media (max-height: 600px)': {
        fontSize: '16px',
    },
  }

  function Slide(props) {
    const classes = useStyles2();

    return (
        <LazyLoad height={200} once>
            <Paper elevation={0} square={true} className={classes.jigpaws}>
                <img className={classes.fullWidth} src={props.item.src} alt="" />
            </Paper>
        </LazyLoad>
    );
}



const LandingPage = () => {
    const classes = useStyles();
    const classes2 = useStyles2();
    const [user, authLoading, authError] = useAuthState(auth);
    const {productType} = useParams();
    const data = productData[productType];

    console.log(data)

    return <>
            <Helmet>
                <title>{data.head.title}</title>
                <meta name="description" content={data.meta.description}/>
                <meta property="og:url" content={data.meta.og_url}/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content={data.meta.og_title}/>
                <meta property="og:description" content={data.meta.og_description}/>
                <meta property="og:image" content={data.meta.og_image}/>
            </Helmet>
            <Container className={"productsHome bgGray"} align="center" disableGutters={true}
                       maxWidth={false}>
                <div className="spacer"/>
                <div className="headerContainer">
                    <div><h1><BalanceText>{data.header.title}</BalanceText></h1></div>
                    <div className="body leader" style={bodyStyle}><BalanceText>{data.header.description}</BalanceText></div>
                    {data.header.description2 && <>
                        <div className="spacerSmall"/>
                        <div className="body leader" style={bodyStyle}><BalanceText>{data.header.description2}</BalanceText>
                        </div>
                    </>}
                </div>
                <div className="spacer"/>
                <div className="jpDivider bgBlue"/>
            </Container>
            <Container maxWidth="xxl" disableGutters={true} spacing={0}>
                <Grid container spacing={0} className="marginBottomFix">
                    <Grid item xs={12} md={6} order={{xs: 2, sm: 2}} className={classes.moveToFront}>
                        <square>
                            <img className="jpLine" src={data.panel_1.image.src}
                                 alt={data.panel_1.image.alt} style={{"width": "100%"}}/>
                        </square>
                    </Grid>
                    <Grid item xs={12} md={6} className="jpTile bgWhite" order={{xs: 1, sm: 1}}>
                        <Hidden smUp={true}>
                            <CenterGrid >
                                <Grid>
                                    <div className="jpTileInner jPDynamic">
                                        <h2>{data.panel_1.title}</h2>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p>{data.panel_1.description}</p>
                                            <div className="spacerSmall"/>
                                        </div>
                                        <div className="spacerSmall"/>
                                        <ProductPrice user={user} productId={data.consistent.price}
                                                      startingFrom={data.consistent.priceFrom}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href={data.consistent.createLink}>CREATE NOW</Button>
                                    </div>
                                    <div className="productPriceDisclaimer"/>
                                    <div className="spacerMed"/>
                                </Grid>
                            </CenterGrid></Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <div className="productPriceDisclaimer jpProductFooter"></div>
                                <CenterGrid>
                                    <div className="jpTileInner jpProductInner jPDynamic">
                                        <h2>{data.panel_1.title}</h2>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <BalanceText><p>{data.panel_1.description}</p></BalanceText>
                                        </div>
                                        <div className="spacerSmall"/>

                                        <ProductPrice user={user} productId={data.consistent.price}
                                                      startingFrom={true}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined"  sx={buttonStyles}
                                                href={data.consistent.createLink}>CREATE NOW</Button>
                                    </div>
                                </CenterGrid>
                            </Square></Hidden>
                    </Grid>
                </Grid>
                {/*<div className="spacerMed"/>*/}
                <Grid container spacing={0} className="marginBottomFix">
                    <Grid item xs={12} md={6} className="jpTile bgWhite">
                        <Hidden smUp={true}>
                            <CenterGrid >
                                <Grid>
                                    <div className="jpTileInner jPDynamic">
                                        <h2>{data.panel_2.title}</h2>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p>{data.panel_2.description}</p>
                                            <div className="spacerSmall"/>
                                            <p>{data.panel_2.description2}</p>
                                        </div>

                                        {/* <div className="spacerMed"/> */}
                                        {/* <ProductPrice user={user} productId={"suede-cushion-12x12"}

                                                      startingFrom={true}></ProductPrice> */}
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href={data.consistent.createLink}>CREATE NOW</Button>

                                    </div>
                                    <div className="productPriceDisclaimer"/>
                                    <div className="spacerMed"/>
                                </Grid>
                            </CenterGrid></Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <div className="productPriceDisclaimer jpProductFooter"></div>
                                <CenterGrid >
                                    <div className="jpTileInner jpProductInner jPDynamic">
                                        <h2>{data.panel_2.title}</h2>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <BalanceText><p>{data.panel_2.description}</p></BalanceText>
                                            <div className="spacerSmall"/>
                                            <BalanceText><p>{data.panel_2.description2}</p></BalanceText>

                                        </div>

                                    </div>
                                </CenterGrid>
                            </Square></Hidden>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.moveToFront}>
                        <square>
                            <img className="jpLine" src={data.panel_2.image.src}
                                 alt={data.panel_2.image.alt} style={{"width": "100%"}}/>
                        </square>
                    </Grid>
                </Grid>
                {/*<div className="spacerMed"/>*/}
                {/*<Grid>BOOK</Grid>*/}
                <Grid container spacing={0} className="marginBottomFix">
                    <Grid item xs={12} md={6} className={classes.moveToFront}>
                        <square>
                            <img className="jpLine" src={data.panel_3.image.src}
                                 alt={data.panel_3.image.alt} style={{"width": "100%"}}/>
                        </square>
                    </Grid>
                    <Grid item xs={12} md={6} className="jpTile bgWhite">
                        <Hidden smUp={true}>
                            <CenterGrid >
                                <Grid>
                                    <div className="jpTileInner jPDynamic">
                                        <h2>{data.panel_3.title}</h2>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p>{data.panel_3.description}</p>
                                            <p>{data.panel_3.description2}</p>
                                             <div className="spacerSmall"/>
                                            <div className="dottedList">{data.panel_3.list}</div>
                                        </div>
                                    </div>
                                    <div className="productPriceDisclaimer"/>
                                    <div className="spacerMed"/>
                                </Grid>
                            </CenterGrid></Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <div className="productPriceDisclaimer jpProductFooter"></div>
                                <CenterGrid >
                                    <div className="jpTileInner jpProductInner jPDynamic">
                                        <h2>{data.panel_3.title}</h2>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <BalanceText><p>{data.panel_3.description}</p></BalanceText>
                                            <BalanceText><p>{data.panel_3.description2}</p></BalanceText>
                                            <div className="spacerSmall"/>
                                            <div className="dottedList">{data.panel_3.list}</div>
                                        </div>
                                    </div>
                                </CenterGrid>
                            </Square>
                        </Hidden>
                    </Grid>

                </Grid>
                {/*<Grid>CUSHIONS</Grid>*/}
                <Grid container spacing={0} className="marginBottomFix">
                    <Grid item xs={12} md={6} className="jpTile bgWhite">
                        <Hidden smUp={true}>
                            <CenterGrid>
                                <Grid>
                                    <div className="jpTileInner jPDynamic">
                                        <h2>{data.panel_4.title}</h2>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p>{data.panel_4.description}</p>
                                            <div className="spacerSmall"/>
                                            <p>{data.panel_4.description2}</p>
                                            <div className="spacerSmall"/>
                                            <div className="dottedList">{data.panel_4.list}</div>

                                        </div>
                                        {/* <div className="spacerSmall"/> */}
                                        {/* <ProductPrice user={user} productId={"photo-tile"}></ProductPrice> */}

                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href={data.consistent.createLink}>CREATE NOW</Button>
                                    </div>
                                    <div className="productPriceDisclaimer"/>
                                    <div className="spacerMed"/>
                                </Grid>
                            </CenterGrid>
                        </Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <div className="productPriceDisclaimer jpProductFooter"></div>
                                <CenterGrid >
                                    <div className="jpTileInner jpProductInner jPDynamic">
                                        <h2>{data.panel_4.title}</h2>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <BalanceText><p>{data.panel_4.description}</p></BalanceText>
                                            <div className="spacerSmall"/>
                                            <BalanceText><p>{data.panel_4.description2}</p></BalanceText>
                                            <div className="spacerSmall"/>
                                            <div className="dottedList">{data.panel_4.list}</div>
                                        </div>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href={data.consistent.createLink}>CREATE NOW</Button>
                                    </div>
                                </CenterGrid>
                            </Square>
                        </Hidden>
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.moveToFront}>
                        <square>
                            <img className={!data.signOff ? "jpLine" : ""} src={data.panel_4.image.src}
                                 alt={data.panel_4.image.alt} style={{"width": "100%"}}/>
                        </square>
                    </Grid>
                </Grid>
            </Container>

            

        {data.signOffSlider && (
            <>
            {console.log(classes2)}
            {console.log(landingSlider)}


            <Container maxWidth="xxl" disableGutters={true} spacing={0}>
                <Grid container spacing={0} className="marginBottomFix">
                   <Grid item xs={12} md={6} className={classes2.moveToFront}>

                        <Square>
                            <div className="mobileNone">
                            {/* <img className="jpLine" src="/images/Homepage2023/JP_Home_Hero_1.webp" width="100%" alt="Jigpaws customised cat puzzle and can"/> */}
                                <Slider {...slider_settings}>
                                    {
                                        landingSlider.map((item, i) => <Slide key={i} item={item}/>)
                                    }
                                </Slider>
                            </div>
                            <div className="desktopNone">
                                <img className="jpLine" src="/images/Homepage2023/JP_Home_Hero_1.webp" width="100%" alt="Jigpaws customised cat puzzle and can"/>
                            </div>
                        </Square>
                    </Grid>

                    

                    <Grid item xs={12} md={6} className="jpTile bgWhite">
                        <Hidden smUp={true}>
                            <CenterGrid >
                                <Grid>
                                    <div className="jpTileInner jPDynamic">
                                        <h2>{data.signOffSlider.title}</h2>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p>{data.signOffSlider.description}</p>
                                            <p>{data.signOffSlider.description2}</p>
                                            <div className="spacerSmall"/>
                                            <div className="dottedList">{data.signOffSlider.list}</div>
                                        </div>
                                    </div>
                                    <div className="productPriceDisclaimer"/>
                                    <div className="spacerMed"/>
                                </Grid>
                            </CenterGrid>
                        </Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <div className="productPriceDisclaimer jpProductFooter"></div>
                                <CenterGrid >
                                   <div className="jpTileInner jpProductInner jPDynamic">
                                        <h2>{data.signOffSlider.title}</h2>
                                       <div className="spacerMed"/>
                                        <div className="body">
                                          <BalanceText><p>{data.signOffSlider.description}</p></BalanceText>
                                           <BalanceText><p>{data.signOffSlider.description2}</p></BalanceText>
                                           <div className="spacerSmall"/>
                                           <div className="dottedList">{data.signOffSlider.list}</div>
                                        </div>
                                   </div>
                               </CenterGrid>
                            </Square>
                        </Hidden>
                    </Grid>
                </Grid>
                
            </Container>
            </>
        )}


        {data.signOff && (
         <Container 
            className={"productsHome bgGreen"} 
            align="center" 
            disableGutters={true}
            maxWidth={false}            
        >
            <div className="spacer"/>
            <div className="headerContainer">
                <div><h1><BalanceText>{data.signOff.title}</BalanceText></h1></div>
                <div className="body leader" style={bodyStyle}><BalanceText>{data.signOff.description}</BalanceText></div>
                <div className="spacer"/>
                    <CustomButton type={"smallwhite"} href={data.consistent.createLink} >CREATE NOW</CustomButton>

                        {data.signOff.description2 && <>
                            <div className="spacerSmall"/>
                            <div className="body leader" style={bodyStyle}><BalanceText>{data.signOff.description2}</BalanceText>
                            </div>
                        </>
                    }

                </div>
                <div className="spacer"/>
            </Container>
            )}


            <ShippingFooter international={true} />


        </>;
};

export default LandingPage
