import {doc, getDoc, setDoc, updateDoc, serverTimestamp, deleteField, query, collection, where } from "firebase/firestore";
import {db} from "./firebase";
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import {useCollectionData} from "react-firebase-hooks/firestore";
import Dinero from "dinero.js";
import {jigpawsDineroFormatted} from "./utils";


export const usePhotobook = (uid, uuid) => {
    const photobookQuery = query(
        collection(db, "photobooks").withConverter(photobookConverter),
        where("uid", "==", uid)
    );

    const [photobooks, photobooksLoading, photobookError] = useCollectionData(
        photobookQuery,
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );

    if (!photobooksLoading && !photobookError && photobooks.length === 0) {
        createPhotobook(uid);
    }

    const photobook = _.find(photobooks, {"id": uuid});
    // console.log("photobooks", photobooks);

    return [photobook, photobooksLoading, photobookError];
};


export const createPhotobook = async (uid) => {
    const ref = doc(db, "photobooks", uid).withConverter(photobookConverter);
    // console.log("creating photobook document...");
    await setDoc(ref, new Photobook(uid, uid), {merge: true});
};


export const useBasket = (user) => {
    const basketQuery = query(
        collection(db, "baskets").withConverter(basketConverter),
        where("uid", "==", user.uid)
    );

    const [baskets, basketsLoading, basketsError] = useCollectionData(
        basketQuery,
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );

    if (!basketsLoading && !basketsError && baskets.length === 0) {
        createBasket(user.uid);
    }

    let basket = null;
    if (baskets) {
        basket = baskets[0];
    }
    return [basket, basketsLoading, basketsError];
};


export const createBasket = async (uid) => {
    const ref = doc(db, "baskets", uid).withConverter(basketConverter);
    // console.log("creating basket document...");
    await setDoc(ref, new Basket(uid), {merge: true});
};

export const emptyBasket = async (uid) => {
    const ref = doc(db, "baskets", uid);
    await setDoc(ref, {
        uid: uid,
        uuid: uuidv4(),
        createdAt: serverTimestamp(),
    }, {merge: false});
};

export const createJigpaw = async (uid, uuid, oid, history, path) => {
    const docId = (oid && oid !== uid) ? uid + '_' + uuid : uuid;
    // console.log("createJigpaw...", uid, uuid, oid, "docId", docId);
    const ref = doc(db, "jigpaws", docId).withConverter(jigpawConverter);
    await setDoc(ref, new Jigpaw(uid, uuid, oid), {merge: true});
    const docSnap = await getDoc(ref);

    if (history && path) {
        history.push(path);
    }

     if (docSnap.exists()) {
    //   console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
    //   console.log("No such document!");
    }

};

export const createStockImages = (uid) => {
    // console.log("createStockImages", uid);
    createJigpaw(uid, "stock1", "system");
    createJigpaw(uid, "stock2", "system");
    createJigpaw(uid, "stock3", "system");
    createJigpaw(uid, "stock4", "system");
};

class Jigpaw {
    constructor (uid, uuid, oid, createdAt, deleted, filename, previews, stored, original) {
        this.createdAt = createdAt;
        this.deleted = deleted ? deleted : false;
        this.filename = filename;
        this.oid = oid ? oid : uid;
        this.previews = previews;
        this.stored = stored;
        this.uid = uid;
        this.uuid = uuid;
        this.original = original;
    }

    linkUUID() {
        return (_.get(this, "oid", this.uid) === this.uid) ? this.uuid : `${this.uid}_${this.uuid}`;
    }

    mark_deleted() {
        let doc_id = this.uuid;
        if (this.oid === "system") {
            doc_id = this.uid + "_" + doc_id;
        }
        const ref = doc(db, "jigpaws", doc_id);
        setDoc(ref, {deleted: true,}, {merge: true});
    }
}

// Firestore data converter
export const jigpawConverter = {
    toFirestore: (jigpaw) => {
        return {
            createdAt: jigpaw.createdAt ? jigpaw.createdAt : serverTimestamp(),
            deleted: jigpaw.deleted,
            ...(jigpaw.filename && {filename: jigpaw.filename}),
            oid: jigpaw.oid,
            ...(jigpaw.previews && {filename: jigpaw.previews}),
            ...(jigpaw.stored && {filename: jigpaw.stored}),
            uid: jigpaw.uid,
            uuid: jigpaw.uuid,
            ...(jigpaw.original && {filename: jigpaw.original}),
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Jigpaw(data.uid, data.uuid, data.oid, data.createdAt, data.deleted, data.filename, data.previews, data.stored, data.original);
    }
};

const productDisplayCategory = (category) => {
    return {"greeting_card": "CARD",
            "journal": "NOTEBOOK",
            "photo_tile": "PHOTO TILE",
            "cushion": "CUSHION",
            "photobook": "PHOTO BOOK",
            "jigsaw": "JIGSAW",
            "calendar": "CALENDAR"}[category];
};
const productDisplayDetails = (category) => {
    return {"greeting_card": "324gsm, Matte or Gloss ",
            "journal": "64 Pages, Lined, Plain or Graph",
            "photo_tile": "20 x 20 cm",
            "cushion": "Available in 2 sizes",
            "photobook": "20 customisable pages",
            "jigsaw": "500 or 1000 pieces",
            "calendar": "CALENDAR"}[category];
};

const productDetails = (category) => {
    if (category === 'notebook') {
        category = 'journal'
    }
    return {
        'jigsaw': '500pc (50x38cm)\n' +
            '1000pc (66x50cm)\n' +
            'Printed on High-Quality Card\n' +
            'Printed in Vibrant Colour\n' +
            'Secure in an Elegant Box and Protective Pouch\n' +
            'Hand Printed to Order\n',
        'cushion': 'Available in 2 sizes:\n' +
            '30 x 30cm or 46 x 46cm\n' +
            'Created Using High-Quality Faux-Suede\n' +
            'Zipped and Machine Washable\n' +
            'Printed in Vibrant Colour\n' +
            '100% Polyester Silk Finish\n',
            'photobook': 'Available in 2 sizes:\n' +
            '14 x 14 cm or 21 x 21 cm\n' +
            '20 Customisable Pages Per Book\n' +
            '2.5mm Hardcover with a Matte Laminate Finish\n' +
            'Durable Library Binding\n' +
            '170gsm Satin-Finished, Sustainably Sourced Paper\n',
        'journal': 'Available with:\n' +
            'Plain Pages, Lined Pages or Graph Pages\n' +
            '64 Pages\n' +
            'Durable Hardback Cover\n' +
            'Glossy Finish\n',
        'photo_tile':
            '20 x 20 cm \n' +
            'Strong Perspex Front\n' +
            'Hand-Made Black Frame\n',
        'greeting_card':
            'Available With 2 Finishes: \n' +
            'Matte or Gloss\n' +
            'Printed In Vibrant Colour\n' +
            'Heavyweight Card (324gsm)\n',
    }[category]
}

const productDescriptionLong = (category) => {
    if (category === 'notebook') {
        category = 'journal'
    }
    return {
        'jigsaw': 'Create edit your new pet jigsaw with our Jigpaws editing tool. Simply crop and resize your image Crop, change or add emojis to your jigsaw easily to and see how your pet appears on your brand-new gift!',
        'cushion': 'Create edit your new cushion product with our Jigpaws editing tool. Simply crop and resize your image Crop, change or add emojis to your jigsaw easily to and see how your pet appears on your brand-new gift!',
        'photobook': 'Create edit your new pet photobook with our Jigpaws editing tool. Simply crop and resize your image Crop, change or add emojis to your jigsaw easily to and see how your pet appears on your brand-new gift!',
        'journal': 'Create edit your new pet notebook with our Jigpaws editing tool. Simply crop and resize your image Crop, change or add emojis to your jigsaw easily to and see how your pet appears on your brand-new gift!',
        'photo_tile':
            'Create edit your new pet photo tile with our Jigpaws editing tool. Simply crop and resize your image Crop, change or add emojis to your jigsaw easily to and see how your pet appears on your brand-new gift!',
        'greeting_card':
            'Create edit your new pet card with our Jigpaws editing tool. Simply crop and resize your image Crop, change or add emojis to your jigsaw easily to and see how your pet appears on your brand-new gift!',
    }[category]
}

const productDisplayVariant = (id) => {
    return {
        'matte-greeting-card': 'Matte',
        'gloss-greeting-card': 'Gloss',
        'lined-journal': 'Lined pages',
        'plain-journal': 'Plain pages',
        'graph-journal': 'Graph pages',
        'photo-tile': 'Photo title',
        'suede-cushion-12x12': '30x30cm',
        'suede-cushion-18x18': '42x42cm',
        'jigsaw-500-50x38': '500 pieces, 50x38cm',
        'jigsaw-1000-66x50': '1000 pieces, 66x50cm',
        'photobook-14x14': '14x14cm',
        'photobook-21x21': '21x21cm',
    }[id]
}

// not used with firebase client
class Product {
    constructor (category, preview, comingSoon, currency, description, description1, description2,  discounts, display_order, id, name, price, promo, promoInReceipt, quantity_max, quantity_min, variant) {
        this.category = category;
        this.preview = preview;
        this.comingSoon = comingSoon;
        this.currency = currency;
        this.description = description;
        this.description1 = description1;
        this.description2 = description2;
        this.discounts = discounts;
        this.display_order = display_order;
        this.id = id;
        this.name = name;
        this.price = price;
        this.promo = promo;
        this.promoInReceipt = promoInReceipt;
        this.quantity_max = quantity_max;
        this.quantity_min = quantity_min;
        this.variant = variant;
        this.displayVariant = productDisplayVariant(this.id);
        this.displayCategory = productDisplayCategory(this.category);
        this.displayDetails = productDisplayDetails(this.category);
        this.details = productDetails(this.category);
        this.detailsLong = productDescriptionLong(this.category);
        // this.displayType = this.name.toUpperCase();
        this.displayType = this.name;
    }

    discountForQuantity(quantity) {
        if (this.discounts) {
            for (let i = 0; i < this.discounts.length; i++) {
                const discountRange = this.discounts[i];
                if (quantity >= discountRange.min && quantity <= discountRange.max && discountRange.discount > 0) {

                    const price = this.priceForQuantity(quantity);
                    const discount = discountRange.discount * price;
                    // console.log("price", price, "discount", discount, "negative", -discount);

                    return {
                        'amount': -discount,
                        'text': discountRange.text,
                        'type': 'product'
                    };
                }
            }
        }
        return null;
    }

    priceForQuantity(quantity) {
        return quantity * this.price;
    }
}

export const productConverter = {
    fromApi: (data) => {
        return new Product(data.category, data.preview, data.comingSoon, data.currency, data.description, data.description1, data.description2, data.discounts, data.display_order, data.id, data.name, data.price, data.promo, data.promoInReceipt, data.quantity_max, data.quantity_min, data.variant);
    }
};

class Country {
    constructor (name, code) {
        this.name = name;
        this.code = code;
    }
}

export const countryConverter = {
    fromApi: (data) => {
        return new Country(data.name, data.isoCode)
    }
}


class Item {
    constructor(createdAt, jigpawId, oid, product, quantity, preview, category) {
         this.createdAt = createdAt;
         this.jigpawId = jigpawId;
         this.oid = oid;
         this.product = product;
         this.quantity = quantity;
         this.preview = preview;
         this.category = category;
    }

    displayCategory() {
        return productDisplayCategory(this.category);
    }
}


class Basket {
    constructor(uid, uuid, items, createdAt, discount_code, merging) {
        this.uid = uid;
        this.uuid = uuid || uuidv4();
        this.items = _.transform(items || {}, (result, item, key) => {
            result[key] = new Item(item.createdAt, item.jigpawId, item.oid, item.product, item.quantity, item.preview, item.category);
        }, {});
        this.createdAt = createdAt;
        this.discount_code = discount_code;
        this.merging = merging || false;
    }

    makeItemKey(jigpawId, productId) {
        return `${jigpawId}${productId}`;
    }

    hasItem(jigpawId, productId) {
        const key = this.makeItemKey(jigpawId, productId);
        return this.items.hasOwnProperty(key);
    }

    containsPhotobook() {
        return this.items.hasOwnProperty("photobook");
    }

    hasItems() {
        return !_.isEmpty(this.items);
    }

    hasPhotobook() {
        return _.has(this.items, "photobook");
    }

    addPhotobook(photobook) {
        // const preview = _.get(jigpaw, `previews.${product.category}`)
        const ref = doc(db, "baskets", this.uid);
        setDoc(ref, {
            items: {
                photobook: {
                    createdAt: serverTimestamp(),
                    jigpawId: photobook.frontPage.jigpawId,
                    oid: photobook.frontPage.oid,
                    product: photobook.productId,
                    quantity: 1,
                    preview: "photobook",
                    category: "photobook",
                }
            }
        }, {merge: true});
    }

    updatePhotobookFrontPage(jigpaw) {
        const ref = doc(db, "baskets", this.uid);
        setDoc(ref, {
            items: {
                photobook: {
                    jigpawId: jigpaw.uuid,
                    oid: jigpaw.oid,
                }
            }
        }, {merge: true});
    }

    updatePhotobookProduct(productId) {
        const ref = doc(db, "baskets", this.uid);
        updateDoc(ref, {
            [`items.photobook.product`]: productId
        });
    }

    updatePhotobookQuantity(quantity) {
        const ref = doc(db, "baskets", this.uid);
        updateDoc(ref, {
            [`items.photobook.quantity`]: parseInt(quantity, 10)
        });
    }

    removePhotobook() {
        const ref = doc(db, "baskets", this.uid);
        updateDoc(ref, {
            [`items.photobook`]: deleteField()
        });
    }

    addItem(jigpaw, product, quantity) {

        const itemKey = this.makeItemKey(jigpaw.uuid, product.id);
        const preview = _.get(jigpaw, `previews.${product.category}`)
        const ref = doc(db, "baskets", this.uid);

        setDoc(ref, {
            items: {
                [itemKey]: {
                    createdAt: serverTimestamp(),
                    jigpawId: jigpaw.uuid,
                    oid: jigpaw.oid,
                    product: product.id,
                    quantity: quantity,
                    ...(preview && {preview: preview}),
                    category: product.category
                }
            }
        }, {merge: true});
    }

    updateItem(jigpawId, productId, quantity) {
        const itemKey = this.makeItemKey(jigpawId, productId);
        const ref = doc(db, "baskets", this.uid);
        updateDoc(ref, {
            [`items.${itemKey}.quantity`]: parseInt(quantity, 10)
        });
    }

    removeItem(jigpawId, productId) {
        const itemKey = this.makeItemKey(jigpawId, productId);
        const ref = doc(db, "baskets", this.uid);
        updateDoc(ref, {
            [`items.${itemKey}`]: deleteField()
        });
    }

    itemQuantity(jigpawId, productId) {
        const itemKey = this.makeItemKey(jigpawId, productId);
        let quantity = 1;
        try {
            quantity = this.items[itemKey].quantity
        }
        catch(e) {}
        return quantity
    }

    totalPrice(products, basketDiscount) {
        console.log("basket.totalPrice: basketDiscount", basketDiscount)
        let groupedItemPrices = []; // basketItems on iOS
        let discounts = [];
        const items_list = Object.values(this.items)
        console.log(items_list)
        if (items_list) {
            const grouped_items = _.groupBy(items_list, 'product');
            _.forEach(products, (product) => {
                const items = grouped_items[product.id]
                if (items) {
                    let quantityOfProduct = 0
                    const sortedItems = _.sortBy(items, 'createdAt');
                    _.forEach(sortedItems, (item) => {
                        quantityOfProduct += item.quantity;
                        groupedItemPrices.push(product.priceForQuantity(item.quantity))
                    })

                    if (!basketDiscount) {
                        const discountForQuantity = product.discountForQuantity(quantityOfProduct);
                        if (discountForQuantity) {
                            // console.log("discountForQuantity", discountForQuantity)
                            discounts.push(discountForQuantity);
                        }
                    }
                }
            })

            const subtotal = _.sum(groupedItemPrices);
            if (basketDiscount) {
                discounts.push(
                    {
                        'type': 'basket',
                        'text': `${basketDiscount.code} ${basketDiscount.percentage}% off`,
                        'amount': -(basketDiscount.percentage / 100) * subtotal
                    }
                )
            }
            const discountTotal = _.sumBy(discounts,'amount');

            return {
                'discounts': discounts,
                'subtotal': subtotal,
                'total': subtotal + discountTotal
            }
        }
        return {}
    }

    totalQuantity() {
        return _.sumBy(_.values(this.items), "quantity")
    }

    removeDiscount() {
        const ref = doc(db, "baskets", this.uid);
        return updateDoc(ref, {
            'discount_code': deleteField()
        });
    }
}

export const basketConverter = {
    toFirestore: (basket) => {
        return {
            uid: basket.uid,
            uuid: basket.uuid,
            ...(basket.items && {items: basket.items}),
            createdAt: basket.createdAt ? basket.createdAt : serverTimestamp(),
            ...(basket.discount_code && {discount_code: basket.discount_code}),
            merging: basket.merging
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Basket(data.uid, data.uuid, data.items, data.createdAt, data.discount_code, data.merging);
    }
};


// class Page {
//     constructor(jigpawId, oid) {
//          this.jigpawId = jigpawId;
//          this.oid = oid;
//     }
//
//     // displayCategory() {
//     //     return productDisplayCategory(this.category);
//     // }
//     toDict() {
//         return {
//             jigpawId: this.jigpawId,
//             oid: this.oid,
//         }
//     }
// }

class Photobook {
    constructor(id, uid, uuid, frontPage, pages, productId, createdAt) {
        this.id = id;
        this.uid = uid;
        this.uuid = uuid || uuidv4();
        this.frontPage = frontPage;
        this.pages = pages;
        this.productId = productId || _.head(Photobook.sizes).productId;
        // this.pages = _.transform(pages || {}, (result, page, key) => {
        //     result[key] = new Page(page.jigpawId, page.oid);
        // }, {});
        this.createdAt = createdAt;
    }

    static pageKeys() {
        return _.times(20, (num)=> {
            return `page${num+1}`
        })
    }

    static pageKeysIncludingBlanks() {
        return _.times(21, (num)=> {
            return `page${num}`
        })
    }

    static isFrontBlank(pageNumber) {
        return (pageNumber === 0) ? true : false;
    }

    static isBackBlank(pageNumber) {
        return (pageNumber === 21) ? true : false;
    }

    static isPage(pageNumber) {
        const pageKey = `page${pageNumber}`;
        return _.includes(Photobook.pageKeys(), pageKey);
    }

    static sizes = [
        {
            display: "14x14cm",
            productId: "photobook-14x14",
        },
        {
            display: "21x21cm",
            productId: "photobook-21x21",
        }
    ]

    cost(photobooks, currency) {
        // console.log("photobooks", photobooks);
        const photobook = _.find(photobooks, {id: this.productId});
        const amount = _.get(photobook, "price", "2000");
        return jigpawsDineroFormatted(Dinero({amount: amount, currency: currency}));
    }

    updateProductId(value, basket) {
        const ref = doc(db, "photobooks", this.uid);
        setDoc(ref, {
            productId: value
        }, {merge: true});

        if (basket && basket.containsPhotobook()) {
            // console.log("got here...")
            basket.updatePhotobookProduct(value);
        } else {
            // console.log("got here2...")
        }
    }


    updateFrontPage(jigpaw, basket) {
        const ref = doc(db, "photobooks", this.uid);
        setDoc(ref, {
            frontPage: {
                jigpawId: jigpaw.uuid,
                oid: jigpaw.oid,
            }
        }, {merge: true});

        if (basket && basket.containsPhotobook()) {
            basket.updatePhotobookFrontPage(jigpaw);
        }
    }

    deleteFrontPage() {
        const ref = doc(db, "photobooks", this.uid);
        updateDoc(ref, {
            [`frontPage`]: deleteField()
        });
    }

    getPage(pageNumber) {
        return _.get(this.pages, `page${pageNumber}`)
    }

    updatePage(pageNumber, jigpaw) {

        const pageKey = `page${pageNumber}`;
        if (!Photobook.isPage(pageNumber)) {
            // console.log("shouldn't get here...")
        }

        const ref = doc(db, "photobooks", this.uid);
        setDoc(ref, {
            pages: {
                [pageKey]: {
                    jigpawId: jigpaw.uuid,
                    oid: jigpaw.oid,
                }
            }
        }, {merge: true});
    }

    updatePages(pages) {
        const data = _.transform(pages, (result, page, index) => {
            const pageKey = `page${index+1}`;
            result[pageKey] = (page) ? page : deleteField()
        }, {})
        const ref = doc(db, "photobooks", this.uid)
        // console.log(data);
        setDoc(ref, {pages: data}, {merge: true});
    }

    deletePage(pageNumber) {
        const pageKey = `page${pageNumber}`;
        const ref = doc(db, "photobooks", this.uid);
        updateDoc(ref, {
            [`pages.${pageKey}`]: deleteField()
        });
    }

    hasFrontPage() {
        return _.has(this.frontPage, "jigpawId")
    }

    hasAllPages() {
        return _.isEqual(_.sortBy(_.keys(this.pages)), _.sortBy(Photobook.pageKeys()))
    }

    isComplete() {
        //check we have a jigpaw image for each page.
        return this.hasFrontPage() && this.hasAllPages()
    }
}

export const photobookConverter = {
    toFirestore: (photobook) => {
        return {
            uid: photobook.uid,
            uuid: photobook.uuid,
            ...(photobook.frontPage && {frontPage: photobook.frontPage}),
            ...(photobook.pages && {pages: photobook.pages}),
            productId: photobook.productId ? photobook.productId : _.head(Photobook.sizes).productId,
            createdAt: photobook.createdAt ? photobook.createdAt : serverTimestamp(),
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        // console.log("photobook snapshot:", snapshot);
        // console.log("options:", options);
        // console.log("data:", data);
        return new Photobook(snapshot.id, data.uid, data.uuid, data.frontPage, data.pages, data.productId, data.createdAt);
    }
};

export {Photobook}