import React, {useEffect, useState} from "react";
import {jigpawAPI} from "../../API";
import {FormControl, FormControlLabel, RadioGroup, Typography} from "@mui/material";
import _ from "lodash";
import Dinero from "dinero.js";
import {makeStyles, styled} from "@mui/styles";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import {jigpawsDineroFormatted} from "../../utils";
import Radio from "@mui/material/Radio";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            display: "block",
        },
    },
}));

const CenteredRadio = styled(Radio)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "white",
    '& .MuiSvgIcon-root': {
        width: '1.3em',
        height: '1.3em',
        backgroundColor: "white",
        borderRadius: "50%",
    },
    '&.Mui-checked': {
      color: "black",
      
    }
});

const ShippingOptions = (props) => {
    const {
        user,
        shippingCountry,
        currency,
        preferredShippingMethod,
        setPreferredShippingMethod,
        shippingErrors,
        setShippingErrors,
    } = props;

    const [shippingOptions, setShippingOptions] = useState([]);
    const [showWarning, setShowWarning] = useState(false)

    useEffect(() => {
        console.log("jigpawAPI.shippingOptions init", user, shippingCountry)
        jigpawAPI.shippingOptions(user, shippingCountry, currency).then((data) => {
            //step 1
            console.log("jigpawAPI.shippingOptions", data);
            setShowWarning(_.get(data, 'showJigsawWarning' , false));
            if (_.isEmpty(data.shippingOptions)) {
                setShippingErrors({...shippingErrors, shippingCountry: "Shipping not available."});
            }
            setShippingOptions(data.shippingOptions);
            const shippingMethod = _.get(preferredShippingMethod, "shippingMethod");
            if (!_.includes(shippingOptions, {shippingMethod})) {
                const firstOption = _.head(data.shippingOptions);
                setPreferredShippingMethod(firstOption);
            }

        });
    }, [shippingCountry, currency]);

    const handleChange = (e) => {
        e.preventDefault();
        const shippingMethod = e.target.value;
        console.log(shippingMethod);
        const shipping = _.find(shippingOptions, {shippingMethod: shippingMethod});
        setPreferredShippingMethod(shipping);
    }

    const shippingMethod = _.get(preferredShippingMethod, "shippingMethod");
    const showExpressWarning = (_.includes(["Express", "Overnight"], shippingMethod) && showWarning);

    const classes = useStyles();

    return <>
    <FormControl component="fieldset" classes={classes}>
        <RadioGroup
            aria-label="shipping address"
            name="radio-buttons-group"
            defaultValue="Standard"
            onChange={handleChange}
        >
            {shippingOptions.map((option) => {
                let price = ""
                if (option.shippingPrice === 0) {
                    price = "FREE"
                } else {
                    price = jigpawsDineroFormatted(Dinero({ amount: option.shippingPrice, currency: currency }));
                }
                const label = `${option.shippingMethod} (${option.estimatedDelivery}) `
                return (
                    <FormControlLabel
                        key={option.shippingMethod}
                        value={option.shippingMethod}
                        control={<CenteredRadio />}
                        style={{color: "#413E40"}}
                        label={label} />

                        )
            })}
        </RadioGroup>
        <div style={{ textAlign: "center", color: "#413E40", marginTop: "40%", fontSize: "0.8rem" }}>
                *items shipped once lovingly handcrafted to order
            </div>


    </FormControl>
        { showExpressWarning &&
            <>
                <div className="spacerMed"></div>
                <Typography variant={"body1"} style={{color: "#413E40"}}>*{shippingMethod} shipping is not available for jigsaws,</Typography>
                <Typography variant={"body1"} style={{color: "#413E40"}}>products will ship separately.</Typography>
                <Typography variant={"body1"} style={{color: "#413E40"}}>Click <Link component={RouterLink} to="/deliveries-and-returns" underline="hover">here</Link> for more information on delivery.</Typography>
                <div className="spacerMed"></div>
            </>
        }
        </>;

}

export default ShippingOptions
